// src/components/Navbar.js
import React, { useState } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="fixed top-0 left-0 w-full bg-white shadow-md z-50">
      <div className="container mx-auto flex justify-between items-center py-4 px-6">
        <a href="#hero" className="text-2xl font-bold text-primary">
          <img src="/logo_bottani.png" alt="Bottani" className="h-12" /> {/* Memperbesar logo */}
        </a>

        {/* Burger Menu Icon for Mobile */}
        <div className="md:hidden">
          <button onClick={toggleMenu} className="text-primary focus:outline-none">
            {isOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
          </button>
        </div>

        {/* Navigation Links */}
        <div 
          className={`md:flex md:space-x-6 md:items-center absolute md:relative top-16 md:top-0 left-0 w-full md:w-auto bg-white md:bg-transparent p-4 md:p-0 transition-all duration-300 ease-in-out transform ${isOpen ? 'translate-y-0 opacity-100' : '-translate-y-4 opacity-0'} md:opacity-100 md:translate-y-0`}
        >
          <div className={`flex flex-col md:flex-row md:space-x-6 md:items-center ${isOpen ? 'block' : 'hidden md:block'}`}>
            <a 
              href="#features" 
              className="text-primary hover:text-secondary py-2 md:py-2 transition duration-300 ease-in-out hover:shadow-lg hover:bg-gray-100 rounded-lg p-2 font-bold" // Meningkatkan font weight
            >
              Key Features
            </a>
            <a 
              href="#about" 
              className="text-primary hover:text-secondary py-2 md:py-2 transition duration-300 ease-in-out hover:shadow-lg hover:bg-gray-100 rounded-lg p-2 font-bold" // Meningkatkan font weight
            >
              About
            </a>
            <a 
              href="#contact" 
              className="text-primary hover:text-secondary py-2 md:py-2 transition duration-300 ease-in-out hover:shadow-lg hover:bg-gray-100 rounded-lg p-2 font-bold" // Meningkatkan font weight
            >
              Contact Us
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
