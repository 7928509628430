import React from 'react';
import { motion } from 'framer-motion';
import { FaArrowRight, FaArrowUp, FaArrowDown } from 'react-icons/fa';

const CauseEffect = () => {
  const items = [
    {
      cause: 'Precise & Efficient',
      effect: 'Yields',
      causeIcon: <FaArrowUp className="text-green-300 ml-2" />,
      effectIcon: <FaArrowUp className="text-green-300 ml-2" />,
    },
    {
      cause: 'Cost & Labor',
      effect: 'Profit Margin',
      causeIcon: <FaArrowDown className="text-green-300 ml-2" />,
      effectIcon: <FaArrowUp className="text-green-300 ml-2" />,
    },
    {
      cause: 'Farmer Prosperity',
      causeIcon: <FaArrowUp className="text-green-300 ml-2" />,
      extraInfo: true,
    },
  ];

  return (
    <div className="flex flex-col space-y-4 mt-8">
      {items.map((item, index) => (
        <motion.div
          key={index}
          whileHover={{ scale: 1.05 }}
          className={`relative flex ${
            item.extraInfo ? 'justify-center' : 'justify-between'
          } items-center bg-primary bg-opacity-60 p-4 rounded-lg shadow-md transition-all duration-300 hover:shadow-lg`}
        >
          {/* Cause Item */}
          <motion.div
            initial={{ opacity: 0, x: -30 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: index * 0.15, duration: 0.6 }}
            className="flex items-center space-x-1 md:space-x-2"
          >
            <p className="text-white font-semibold text-sm md:text-base">{item.cause}</p>
            <span className="text-2xl">{item.causeIcon}</span>
          </motion.div>

          {/* Interactive Right Arrow */}
          {item.effect && (
            <motion.div
              whileHover={{ scale: 1.1, x: 5 }}
              className="mx-1 md:mx-2 p-1 rounded-full flex items-center text-white text-lg transition-transform duration-300"
            >
              <FaArrowRight className="text-white" />
            </motion.div>
          )}

          {/* Effect Item */}
          {item.effect && (
            <motion.div
              initial={{ opacity: 0, x: 30 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: index * 0.15, duration: 0.6 }}
              className="flex items-center space-x-1 md:space-x-2"
            >
              <p className="text-white font-semibold text-sm md:text-base">{item.effect}</p>
              <span className="text-2xl">{item.effectIcon}</span>
            </motion.div>
          )}
        </motion.div>
      ))}
    </div>
  );
};

export default CauseEffect;
