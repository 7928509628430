import React from 'react';

const About = () => {
  return (
    <section id="about" className="p-8 bg-gray-50 text-primary">
      <div className="container mx-auto text-center space-y-8">
        <h2 className="text-4xl font-bold text-secondary">About Bottani</h2>
        <hr className="w-24 h-1 bg-secondary mx-auto" />
        <p className="text-lg text-gray-700">Bottani is an agricultural robot that can carry out the process of plant care and monitoring in dryland agriculture from fertilizing, watering, and spraying. Bottani also has various sensors and AI cameras to monitor and find out the condition of plants and the environment such as humidity, temperature, nutrients and soil PH level.</p>
        
        <h3 className="text-3xl font-semibold text-secondary">Our Mission</h3>
        <p className="text-lg text-gray-700">At Bottani, we aim to revolutionize agricultural practices by providing an all-in-one automated solution that minimizes labor costs and maximizes crop yields.</p>
        
        <h3 className="text-3xl font-semibold text-secondary">Innovative Features</h3>
        <p className="text-lg text-gray-700">Bottani autonomously performs essential plant care tasks such as fertilizing, watering, and spraying. Our robot is equipped with advanced sensors and AI cameras that monitor soil conditions, humidity, temperature, nutrient levels, and soil pH, providing real-time insights for optimized crop management.</p>
        
        <h3 className="text-3xl font-semibold text-secondary">Sustainability</h3>
        <p className="text-lg text-gray-700">By utilizing solar energy and reducing reliance on manual labor, Bottani promotes sustainable farming practices that minimize environmental impact while improving efficiency.</p>

        <h3 className="text-3xl font-semibold text-secondary">Watch Our Introduction</h3>
        <div className="video-container mx-auto mt-4 relative" style={{ paddingBottom: '56.25%' }}> {/* Aspect Ratio 16:9 */}
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/eCQdHA7_djg"
            title="Bottani Intro Video"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            className="absolute top-0 left-0 w-full h-full rounded-lg shadow-lg"
          ></iframe>
        </div>
      </div>
    </section>
  );
};

export default About;
