import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { FaEnvelope, FaPhone, FaMapMarkerAlt, FaInstagram } from 'react-icons/fa';

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs
      .send('service_h2mcz06', 'template_gvddkyk', formData, 'DYItVP0my_mSj6DwM')
      .then((response) => {
        console.log('Message sent successfully:', response.status, response.text);
        alert('Your message has been sent!');
        setFormData({ name: '', email: '', message: '' }); // Clear form after submission
      })
      .catch((error) => {
        console.error('Error sending message:', error);
        alert('Failed to send message. Please try again later.');
      });
  };

  return (
    <section id="contact" className="py-16 bg-gray-50 text-primary">
      <div className="container mx-auto text-center space-y-8">
        <h2 className="text-4xl font-bold text-secondary">Contact Us</h2>
        <p className="text-lg text-gray-700">We would love to hear from you! Please fill out the form below.</p>

        <div className="flex flex-col md:flex-row justify-center md:space-x-10 mb-10 space-y-6 md:space-y-0">
          <div className="flex flex-col items-center transition-transform transform hover:scale-110">
            <FaEnvelope className="text-3xl md:text-4xl text-primary" />
            <p className="text-lg mt-2">robotofthings@gmail.com</p>
          </div>
          <div className="flex flex-col items-center transition-transform transform hover:scale-110">
            <FaPhone className="text-3xl md:text-4xl text-primary" />
            <p className="text-lg mt-2">+62-822-8599-7821</p>
          </div>
          <div className="flex flex-col items-center transition-transform transform hover:scale-110">
            <FaMapMarkerAlt className="text-3xl md:text-4xl text-primary" />
            <p className="text-lg mt-2">Bandung, West Java, Indonesia</p>
          </div>
          <div className="flex flex-col items-center transition-transform transform hover:scale-110">
            <FaInstagram className="text-3xl md:text-4xl text-primary" />
            <p className="text-lg mt-2">@robotofthings</p>
          </div>
        </div>

        <form onSubmit={handleSubmit} className="max-w-lg mx-auto space-y-4 p-8 bg-white rounded-lg shadow-lg">
          <input
            type="text"
            name="name"
            placeholder="Your Name"
            value={formData.name}
            onChange={handleChange}
            className="w-full p-3 border rounded-md focus:outline-none focus:ring focus:ring-primary transition duration-300"
            required
          />
          <input
            type="email"
            name="email"
            placeholder="Your Email"
            value={formData.email}
            onChange={handleChange}
            className="w-full p-3 border rounded-md focus:outline-none focus:ring focus:ring-primary transition duration-300"
            required
          />
          <textarea
            name="message"
            placeholder="Your Message"
            value={formData.message}
            onChange={handleChange}
            className="w-full p-3 border rounded-md focus:outline-none focus:ring focus:ring-primary transition duration-300"
            rows="5"
            required
          />
          <button
            type="submit"
            className="w-full p-3 bg-primary text-white font-semibold rounded-md hover:bg-secondary transition duration-300"
          >
            Send Message
          </button>
        </form>
      </div>
    </section>
  );
};

export default ContactUs;
