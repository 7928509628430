// src/components/Hero.js
import React from 'react';
import { Link } from 'react-scroll';
import CauseEffect from './CauseEffect';
import { motion } from 'framer-motion';
import { FaArrowDown } from 'react-icons/fa'; // Import arrow down icon

const Hero = () => {
  return (
    <section className="relative flex h-screen overflow-hidden">
      {/* Background Video */}
      <video
        className="absolute inset-0 w-full h-full object-cover"
        src="/bottani_anim.mp4"
        autoPlay
        loop
        muted
      />
      <div className="absolute inset-0 bg-black opacity-50"></div> {/* Overlay for readability */}

      {/* Content Section (1/3 Screen on Left) */}
      <div className="relative z-10 flex flex-col justify-center items-start text-left w-full md:w-1/3 px-6">
        <h1 className="text-5xl font-bold text-white">Bottani</h1>
        <p className="text-lg mt-2 text-white">All-in-One Agricultural Solution.</p>
        
        {/* Short description about Bottani. */}
        <p className="text-white mt-4">
          Bottani is an innovative agricultural robot designed for horticulture, automating essential tasks like fertilizing, watering, and spraying. Equipped with advanced sensors and AI cameras, Bottani monitors plant and environmental conditions, ensuring optimal crop care and management.
        </p>

        {/* CauseEffect Component */}
        <CauseEffect />
      </div>

      {/* Discover More Button in Center */}
      <motion.div
        className="absolute inset-x-0 bottom-10 flex justify-center z-20" // Center horizontally and bring to front
        initial={{ scale: 1 }}
        animate={{ scale: [1, 1.05, 1] }} // Animation effect: scale up and down
        transition={{ duration: 1, repeat: Infinity, ease: "easeInOut" }} // Continuous scaling animation
      >
        <Link to="about" smooth={true} duration={500}>
          <motion.button
            className="flex items-center border-2 border-transparent bg-secondary hover:bg-additional1 text-white font-bold py-2 px-4 rounded-full transition duration-300"
            whileHover={{ borderColor: '#fff', scale: 1.05 }} // Highlight border on hover
            style={{ boxShadow: '0 0 10px rgba(255, 255, 255, 0.5)' }} // Glow effect
          >
            Discover More
            <FaArrowDown className="ml-2" /> {/* Arrow down icon */}
          </motion.button>
        </Link>
      </motion.div>
    </section>
  );
};

export default Hero;
