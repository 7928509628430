import React, { useState } from 'react';
import { FaSolarPanel, FaRegChartBar, FaWater, FaRobot, FaDatabase, } from 'react-icons/fa';

const featuresList = [
  {
    title: "Solar Power",
    description: "Bottani operates on renewable solar energy, ensuring sustainability while minimizing operational costs.",
    icon: <FaSolarPanel className="w-16 h-16 text-primary mx-auto" />,
  },
  {
    title: "AI Monitoring",
    description: "Equipped with AI-powered sensors and cameras, Bottani monitors soil conditions, humidity, temperature, and nutrient levels for optimal crop management.",
    icon: <FaRegChartBar className="w-16 h-16 text-primary mx-auto" />,
  },
  {
    title: "Automated Fertigation",
    description: "Bottani precisely controls the application of water and fertilizers, enhancing crop yield while conserving resources.",
    icon: <FaWater className="w-16 h-16 text-primary mx-auto" />,
  },
  {
    title: "Autonomous Operation",
    description: "Bottani performs key agricultural tasks such as fertilizing, watering, and spraying without the need for human intervention, allowing farmers to focus on other important aspects.",
    icon: <FaRobot className="w-16 h-16 text-primary mx-auto" />,
  },
  {
    title: "Data Analytics",
    description: "With cloud-based data storage and analytics, Bottani provides historical insights that help farmers make data-driven decisions to improve productivity.",
    icon: <FaDatabase className="w-16 h-16 text-primary mx-auto" />,
  },
//   {
//     title: "User-Friendly Interface",
//     description: "Bottani features an intuitive app that allows users to control and monitor their robotic system easily, making technology accessible for all farmers.",
//     icon: <FaUserAlt className="w-16 h-16 text-primary mx-auto" />,
//   },
];

const Features = () => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const toggleFeature = (index) => {
    setExpandedIndex(index === expandedIndex ? null : index);
  };

  return (
    <section id="features" className="py-16 px-6 bg-white">
      <div className="container mx-auto">
        <h2 className="text-4xl font-bold text-primary text-center mb-8">Key Features</h2>

        <div className="grid md:grid-cols-3 gap-6">
          {featuresList.map((feature, index) => (
            <div 
              key={index} 
              className="relative bg-gray-100 rounded-lg shadow-md transition-all duration-300 ease-in-out transform hover:scale-105 hover:bg-gray-200 p-6 flex flex-col justify-between cursor-pointer" // Added cursor-pointer
              onClick={() => toggleFeature(index)} // Moved here
            >
              <div className="flex flex-col items-center">
                <div className="mb-4">
                  {feature.icon}
                </div>
                <h3 className="text-xl font-semibold text-secondary mb-2">
                  {feature.title}
                </h3>
              </div>
              <p className={`text-gray-700 mt-4 transition-all duration-300 ease-in-out ${expandedIndex === index ? 'max-h-screen' : 'max-h-0 overflow-hidden'}`}>
                {feature.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Features;
